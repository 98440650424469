<!--
 * @Descripttion:
 * @version:
 * @Author: huangziting
 * @Date: 2021-01-26 08:30:37
 * @LastEditTime: 2021-11-17 19:16:02
-->
<template>
  <login-common>
    <div class='login-wrapper'>
      <div class='login-ways'>
        <div class='ways-item check'>账号密码登录</div>
      </div>
      <div class='login-form mt10'>
        <el-form
          :model='loginRef'
          :rules='loginRules'
          ref='loginForm'
          class='demo-ruleForm'
        >
          <el-form-item class='login-item' prop='username'>
            <div class='login-box'>
              <div class='image-wrapper'>
                <el-icon :size='25' color='#909399'>
                  <Iphone />
                </el-icon>
              </div>
              <el-input
                v-model='loginRef.username'
                placeholder='请输入手机号码 / 身份证号码'
                @keyup.enter.native='login'
              />
            </div>
          </el-form-item>
          <el-form-item class='login-item' prop='password'>
            <div class='login-box'>
              <div class='image-wrapper'>
                <el-icon :size='25' color='#909399'>
                  <Lock />
                </el-icon>
              </div>
              <el-input
                type='password'
                v-model='loginRef.password'
                placeholder='请输入登录密码'
                @keyup.enter='login'
              />
            </div>
          </el-form-item>
          <el-form-item class='login-item' prop='captcha'>
            <div class='login-box'>
              <div class='image-wrapper'>
                <el-icon :size='25' color='#909399'>
                  <Picture />
                </el-icon>
              </div>
              <el-input
                v-model='loginRef.captcha'
                class='mr5'
                placeholder='请输入图形码'
                @keyup.enter='login'
              />
              <el-image
                :src='imageUrl'
                style='display: flex; height: 38px; cursor: pointer'
                @click='getIamge'
              />
            </div>
          </el-form-item>
          <el-form-item class='login-item' style='margin-bottom: 0'>
            <el-button
              type='primary'
              @click='login'
              style='width: 100%'
              :loading='loading'
            >{{ loading ? '登陆中，请耐心等待...' : '立即登录' }}
            </el-button>
          </el-form-item>
        </el-form>
        <router-link class='forget-pass' to='/forgetThePassword'
        >忘记密码
        </router-link>
      </div>
      <div class='login-tops'>
        <span>还未注册账号?</span>
        <span class='register' @click='register'>马上注册</span>
      </div>
      <div class='tip'>
        <div style='padding: 5px'>
          温馨提示：已在选房网注册过请使用选房网账号登录
        </div>
      </div>
    </div>
  </login-common>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { rentLogin, randomImage } from '@/api/login'
import { useStore } from 'vuex'
import CryptoJS from 'crypto-js'
import { defineComponent, reactive, ref, onMounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import LoginCommon from '@/components/NesiLoginCommon/index.vue'

defineComponent({
  LoginCommon
})
const loading = ref(false)
const router = useRouter()
const store = useStore()
const loginForm = ref(null)
const loginRef = reactive({
  username: '',
  password: '',
  captcha: '',
  checkKey: '',
  codeText: '获取验证码'
})
const loginRules = reactive({
  username: [
    {
      required: true,
      message: '请输入手机号码/身份证号码',
      trigger: 'blur'
    }
  ],
  password: [{ required: true, message: '请输入登录密码', trigger: 'blur' }],
  captcha: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
})

// des 加密
const encryptByDES = (message, key, iv) => {
  const keyHex = CryptoJS.enc.Utf8.parse(key)
  const keyIv = CryptoJS.enc.Utf8.parse(iv)
  const srcs = CryptoJS.enc.Utf8.parse(message)
  const encrypted = CryptoJS.AES.encrypt(srcs, keyHex, {
    iv: keyIv,
    mode: CryptoJS.mode.CBC
  })
  return encrypted.toString()
}
const login = () => {
  loginForm.value.validate(async (valid) => {
    if (!valid) return
    const key = loginRef.username.substring(0, 8) + 'Easyma3-'
    const desCode = encryptByDES(loginRef.password, key, key)
    const payload = {
      username: loginRef.username,
      password: desCode,
      captcha: loginRef.captcha,
      checkKey: timestamp.value
    }
    try {
      loading.value = true
      const res = await rentLogin(payload)
      if (res.code === 200) {
        const userInfo = res.result.userInfo
        userInfo.govRole = res.result.govRole
        userInfo.propertyRole = res.result.propertyRole
        // 保存 token 和 user 到 store
        store.commit('user/setUserInfo', userInfo)
        store.commit('user/setToken', res.result.token)
        store.commit('user/setTenant', false)

        ElMessage.success('登录成功！')
        if (
          userInfo.faceAuth !== 1 ||
          userInfo.resetPwd !== 2 ||
          userInfo.source === 2
        ) {
          router.push('/home')
        } else {
          router.push('/user/house/index')
        }
      } else {
        if (res.code === 500 && res.message === '密码已过期,请修改密码!') {
          ElMessageBox.confirm(
            '当前账号需要强行重置密码，是否前往重置密码？',
            '温馨提示',
            {
              confirmButtonText: '确认',
              cancelButtonText: '取消',
              type: 'warning'
            }
          )
            .then(() => {
              // 重置密码
              router.push('/forgetThePassword')
            })
            .catch(() => {
            })
        } else {
          ElMessage.error(res.message)
        }
      }
    } finally {
      loading.value = false
    }
  })
}
const register = () => {
  router.push('/register')
}
const imageUrl = ref('')
const timestamp = ref('')
onMounted(() => {
  getIamge()
  // enterEvent()
})
const getIamge = async () => {
  const key = new Date().getTime()
  const resp = await randomImage({ key })
  timestamp.value = key
  imageUrl.value = resp
}

// const enterEvent = () => {
//   document.onkeydown = (e) => {
//     const body = document.getElementsByTagName('body')[0]
//     console.log('body: ', body)
//     console.log('e.target.baseURI: ', e.target.baseURI)
//     console.log('e.target: ', e.target)
//     if (
//       e.keyCode === 13 &&
//       e.target.baseURI.match(/login/) &&
//       e.target === body
//     ) {
//       // match(此处应填写文件在浏览器中的地址，如 '/home/index')，不写的话，其他页面也会有调用回车按下的方法
//       debugger
//       login() // 调用查询方法
//     }
//   }
// }
</script>

<style scoped lang='scss'>
::v-deep .el-form-item__content {
  margin: 0 auto !important;
}

::v-deep .el-input__inner {
  border: 1px solid #eeeeee;
}

::v-deep .el-input__inner:focus {
  border-color: #409eff;
  outline: 0;
}

.login-wrapper {
  background-color: #fff;
  width: 360px;
  border-radius: 10px;
  box-shadow: 0 0 20px #eee;
  // transition: 0.4s all ease-in-out;
  // &:hover {
  //   transform: translateY(-3px);
  //   box-shadow: 0 8px 20px #bbb;
  // }
  .login-ways {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 17px;
    height: 50px;
    line-height: 50px;
    color: #333333;
    width: 80%;
    margin: 0 auto;

    .ways-item {
      &.check {
        color: #268eff;
      }
    }
  }

  .login-form {
    .forget-pass {
      width: 85%;
      margin: 0 auto;
      text-align: right;
      display: block;
      color: #268eff;
      cursor: pointer;
      font-size: 14px;
      margin-top: 5px;
    }

    .demo-ruleForm {
      .login-item {
        width: 85%;
        margin: 0 auto 22px;

        .login-box {
          height: 52px;
          margin: 0 auto;
          display: flex;
          align-items: center;
          background: #f6f6f6;
          border-radius: 5px;
          padding: 0 5px;

          .image-wrapper {
            height: 40px;
            width: 28px;
            display: flex;
            margin-right: 5px;
            align-items: center;

            img {
              height: 28px;
              width: 28px;
            }
          }
        }
      }
    }
  }

  .login-tops {
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 10px;

    .register {
      color: #268eff;
      cursor: pointer;
      margin-left: 5px;
    }
  }

  .tip {
    width: 85%;
    margin: 10px auto;
    font-size: 13px;
    color: #f90;
    background: #fdf6ec;
  }
}

.mr5 {
  margin-right: 5px;
}
</style>
